@import url(https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
@font-face{
    font-family: "Sansation";
    src: url(/static/media/Sansation_Regular.14df84ca.ttf);
}

@font-face {
    font-family: "Matter";
    src: url(/static/media/Matter-Medium.148b1cb7.otf);
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Matter";
    src: url(/static/media/Matter-Light.3a12261f.otf);
    font-style: normal;
    font-weight: 300;
}


:root {
    --color-bg: linear-gradient(90deg, #08062a 0%, #1c1e3c 53.12%, #08062a 100%);
    /*--font-family: 'Sansation', sans-serif;*/
    --font-family: 'Matter', sans-serif;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 5.2rem;
}

body{
    background: black;
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    background: var(--color-bg);
    text-align: center;
}

.section__padding {
    padding: 3rem 6rem;
}

@media screen and (max-width: 900px) {

    .section__padding{
        padding: 1rem 2rem;
    }

}
.about{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.about__title {
    color: white;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 96px;
    line-height: 107px;
    text-align: center;
    padding-top: 3rem;
}

.about__wrapper {
    display: inline-block;
    width: 80%;
    background: #0909187a;
    border-radius: 2%;
}

@media screen and (max-width: 1050px) {

    .about__title {
        font-size: 80px;
        padding-top: 0rem;
    }
}

@media screen and (max-width: 550px) {

    .about__title {
        font-size: 55px;
    }

    .about__wrapper {
        width: 90%;
    }

}
.timelinestyle__TimelineMain-sc-cif21b-2 {
    left: unset !important;
}

.timelinestyle__Outline-sc-cif21b-3 {
    background: #00fff0 !important;
}


/*timeline node borders*/
.timeline-horizontal-cardstyles__Circle-sc-18iuiou-3:not(.using-icon) {
    background: #00fff0 !important;
}

    .timeline-horizontal-cardstyles__Circle-sc-18iuiou-3:not(.using-icon).active::after {
        background: #005050 !important;
    }

/*active timeline node*/
.timeline-card-titlestyles__TitleWrapper-sc-13izrht-0.active {
    background: unset !important;
    color: #ffffff !important;
}

    .timeline-card-titlestyles__TitleWrapper-sc-13izrht-0.active::after {
        position: absolute;
        content: "";
        height: 2px;
        bottom: 0px;
        margin: 5px auto;
        left: 0;
        right: 0;
        width: 80%;
        background: #005050;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

.timeline-card-titlestyles__TitleWrapper-sc-13izrht-0 {
    color: #ffffff !important;
}

/*card container*/
.timeline-horizontal-cardstyles__TimelineContentContainer-sc-18iuiou-5.horizontal {
    min-width: 0 !important;
    padding-right: 14% !important;
    padding-left: 14% !important;
    padding-bottom: 2rem;
}

.timeline__custom-card {
    display: flex;
    flex: 1 1;
    grid-gap: 5rem;
    gap: 5rem;
    align-items: center;
}

.timeline__card-title {
    display: flex;
    font-family: 'Sansation';
    color: #ffffff;
    justify-content: center;
    font-size: 30px;
    padding: 2rem;
}

.timeline__card {
    background: #fff0 !important;
}

.timeline__custom-card-text {
    color: white;
    font-family: 'Sansation';
}

.timeline__custom-card-image {
    width: 100%;
}

.timeline__custom-card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*button tray background*/
.timeline-controlstyles__TimelineNavWrapper-sc-1apb8f9-0 {
    background: unset !important;
}

/*actual button background*/
.timeline-controlstyles__TimelineNavButton-sc-1apb8f9-2 {
    background: #000000 !important;
    color: #00fff0 !important;
}

.timeline-card-contentstyles__TimelineContentDetailsWrapper-sc-d7qjm1-6 {
    max-height: unset !important;
    overflow: unset !important;
}

    .timeline-card-contentstyles__TimelineContentDetailsWrapper-sc-d7qjm1-6.show-less {
        overflow: unset !important;
    }

/*Timeline width*/
.timeline-horizontalstyles__TimelineItemWrapper-sc-1dd6qhe-1 {
    width: 250px !important;
}

/*Timeline node titles*/
.timeline-card-titlestyles__TitleWrapper-sc-13izrht-0{
    font-family: 'Matter';
    font-style: normal;
    font-weight: 300 !important;
}

@media screen and (max-width: 1050px) {
    /*Timeline width*/
    .timeline-horizontalstyles__TimelineItemWrapper-sc-1dd6qhe-1 {
        width: 130px !important;
    }

    /*card container*/
    .timeline-horizontal-cardstyles__TimelineContentContainer-sc-18iuiou-5.horizontal {
        padding-right: 10% !important;
        padding-left: 10% !important;
        padding-bottom: 2rem !important;
    }

    .timeline__custom-card {
        flex-direction: column;
        grid-gap: 0rem;
        gap: 0rem;
    }

    .timeline__custom-card-image {
        width: 50%;
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 550px) {

    /*Timeline width*/
    .timeline-horizontalstyles__TimelineItemWrapper-sc-1dd6qhe-1 {
        width: 90px !important;
    }

    /*card container*/
    .timeline-horizontal-cardstyles__TimelineContentContainer-sc-18iuiou-5.horizontal {
        min-width: 0 !important;
        padding-right: 5% !important;
        padding-left: 5% !important;
    }
}

.blog {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.blog__title {
    color: white;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 96px;
    line-height: 107px;
    text-align: center;
    padding-top: 0rem;
}

.blog__container {
    width: 80%;
    display: inline-block;
}

.blogCardContainer {
    display: flex;
    flex: 1 1;
    justify-content: center;
    align-items: center;
    grid-gap: 2rem;
    gap: 2rem;
    overflow: hidden;
    padding-bottom: 5rem;
}
:root {
    --color-primary: #6b7a8f;
    --color-secondary: #101118;
    --color-accent: #1d1f2f;
    --color-focus: aqua;
    --base-duration: 600ms;
    --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
    --slide-width: 40vmin;
    --slide-margin: 3vmin;
    --slide-height: 60vmin;
}

h1, h2, h3 {
    font-family: 'Playfair Display', serif;
}

.icon {
    fill: #6b7a8f;
    fill: var(--color-primary);
    width: 100%;
}

.btn {
    background-color: #ffffff;
    border: none;
    border-radius: 20rem;
    color: black;
    cursor: pointer;
    font-family: 'Matter';
    font-weight: 300;
    padding: 1rem 2.5rem 1.125rem;
}

    .btn:focus {
        outline-offset: 1px;
    }

    .btn:active {
        -webkit-transform: translateY(1px);
                transform: translateY(1px);
    }

.btn a {
    font-weight: 400;
}

.slider__viewAll {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(100% + 5rem);
    width: 100%;
}

.slider__controls {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(100% + 1rem);
    width: 100%;
}

    .slider__controls .btn {
        --size: 3rem;
        align-items: center;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 100%;
        display: flex;
        height: var(--size);
        padding: 0;
        width: var(--size);
    }

        .slider__controls .btn:focus {
            border-color: aqua;
            border-color: var(--color-focus);
            outline: none;
        }

    .slider__controls .btn--previous > * {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }

.slider {
    height: 60vmin;
    height: var(--slide-height);
    margin: 0 auto;
    position: relative;
    width: 40vmin;
    width: var(--slide-width);
}

.slider__wrapper {
    display: flex;
    margin: 0 calc(3vmin * -1);
    margin: 0 calc(var(--slide-margin) * -1);
    position: absolute;
    transition: -webkit-transform 600ms cubic-bezier(0.25, 1, 0.35, 1);
    transition: transform 600ms cubic-bezier(0.25, 1, 0.35, 1);
    transition: transform 600ms cubic-bezier(0.25, 1, 0.35, 1), -webkit-transform 600ms cubic-bezier(0.25, 1, 0.35, 1);
    transition: -webkit-transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
    transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
    transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1), -webkit-transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.slide {
    align-items: center;
    color: white;
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 60vmin;
    height: var(--slide-height);
    justify-content: center;
    margin: 0 3vmin;
    margin: 0 var(--slide-margin);
    opacity: 0.25;
    position: relative;
    text-align: center;
    transition: opacity calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84), -webkit-transform calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: opacity calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84), transform calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: opacity calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84), transform calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84), -webkit-transform calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: opacity calc(var(--base-duration) / 2) var(--base-ease), -webkit-transform calc(var(--base-duration) / 2) var(--base-ease);
    transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
    transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease), -webkit-transform calc(var(--base-duration) / 2) var(--base-ease);
    width: 40vmin;
    width: var(--slide-width);
    z-index: 1;
}

.slide--previous:hover, .slide--next:hover {
    opacity: 0.5;
}

.slide--previous {
    cursor: w-resize;
}

    .slide--previous:hover {
        -webkit-transform: translateX(2%);
                transform: translateX(2%);
    }

.slide--next {
    cursor: e-resize;
}

    .slide--next:hover {
        -webkit-transform: translateX(-2%);
                transform: translateX(-2%);
    }

.slide--current {
    --x: 0;
    --y: 0;
    --d: 50;
    opacity: 1;
    pointer-events: auto;
    -webkit-user-select: auto;
            user-select: auto;
}

@media (hover: hover) {
    .slide--current:hover .slide__image-wrapper {
        -webkit-transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
                transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }
}

.slide__image-wrapper {
    border-radius: 1%;
    height: 100%;
    left: 0%;
    overflow: hidden;
    position: absolute;
    top: 0%;
    transition: -webkit-transform calc(600ms / 4) cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: transform calc(600ms / 4) cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: transform calc(600ms / 4) cubic-bezier(0.25, 0.46, 0.45, 0.84), -webkit-transform calc(600ms / 4) cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: -webkit-transform calc(var(--base-duration) / 4) var(--base-ease);
    transition: transform calc(var(--base-duration) / 4) var(--base-ease);
    transition: transform calc(var(--base-duration) / 4) var(--base-ease), -webkit-transform calc(var(--base-duration) / 4) var(--base-ease);
    width: 100%;
}

.slide__image {
    --d: 20;
    height: 100%;
    left: 0%;
    object-fit: cover;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0%;
    transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), -webkit-transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), -webkit-transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: opacity var(--base-duration) var(--base-ease), -webkit-transform var(--base-duration) var(--base-ease);
    transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
    transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease), -webkit-transform var(--base-duration) var(--base-ease);
    -webkit-user-select: none;
            user-select: none;
    width: 100%;
}

@media (hover: hover) {
    .slide--current .slide__image {
        -webkit-transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
                transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }
}

.slide__headline {
    font-family: 'Matter';
    font-size: 4vmin;
    font-weight: 400;
    -webkit-filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.5));
            filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.5));
    position: relative;
    background: rgb(5 14 46 / 80%);
    border-radius: 10px;
    padding: 1rem;
}

.slide__content {
    --d: 60;
    opacity: 0;
    padding: 4vmin;
    position: relative;
    transition: -webkit-transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), -webkit-transform 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84);
    transition: -webkit-transform var(--base-duration) var(--base-ease);
    transition: transform var(--base-duration) var(--base-ease);
    transition: transform var(--base-duration) var(--base-ease), -webkit-transform var(--base-duration) var(--base-ease);
    visibility: hidden;
}

.slide--current .slide__content {
    -webkit-animation: fade-in calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84) forwards;
            animation: fade-in calc(600ms / 2) cubic-bezier(0.25, 0.46, 0.45, 0.84) forwards;
    -webkit-animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
            animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
    visibility: visible;
}

@media (hover: hover) {
    .slide--current .slide__content {
        -webkit-transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
                transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
    }
}

.slide__content > * + * {
    margin-top: 2rem;
}

@-webkit-keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width: 1050px){

    .slide__headline {
        font-size: 5vmin;
        font-weight: 600;
        position: relative;
    }

    .btn {
        padding: 0.5rem 1.5rem 0.5rem;
    }

}

@media screen and (max-width: 700px){
    :root {
        --slide-width: 60vmin;
        --slide-height: 90vmin;
    }

    .slide__headline {
        font-size: 6vmin;
        font-weight: 600;
        position: relative;
    }
}
.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #05032a;
    height: 83px;
}

.footer::after{
    content: '';
    flex: 1 1;
}

.footer__copyright {
    flex: 1 1;
    padding-left: 2rem;
}

.footer__copyright p {
    font-family: var(--font-family);
    color: white;
    font-weight: 400;
    font-size: 18px;
}

@media screen and (max-width: 1050px) {
    .footer::after {
        flex: 0 1;
    }

    .footer__copyright p {
        display: flex;
    }
}

@media screen and (max-width: 550px){
    .footer::after {
        flex: 0 1;
    }

    .footer__copyright p {
        font-size: 12px;
    }

    .footer__copyright {
        padding-left: 2rem;
    }
}
.socials__container {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.socials {
    transition: all .2s ease-in-out;
}

    .socials:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2);
        color: white !important;
    }
.header__navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83px;
    background: #070630;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: .2s ease-in;
}

.header__navbar-hidden {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83px;
    background: #ffffff00;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: .2s ease-in;
}

.header__navbar-container {
    display: flex;
    flex-direction: row;
    grid-gap: 37px;
    gap: 37px;
}

    .header__navbar-container a,
    .header__navbar-menu_container-links a {
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        font-family: var(--font-family);
        cursor: pointer;
    }

.header__navbar-menu {
    display: none;
    position: relative;
}

    .header__navbar-menu svg {
        cursor: pointer;
    }

.header__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: rgb(7, 6, 48);
    padding: 2rem;
    position: absolute;
    grid-gap: 20px;
    gap: 20px;
    right: -2rem;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
    z-index: 1;
}

.link {
    display: inline-block;
    position: relative;
}

    .link:active {
        -webkit-transform: translateY(1px);
                transform: translateY(1px);
    }

.active-link::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 100%;
    background-color: aqua;
    left: 0;
    bottom: -5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

/*.link:hover::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 100%;
    background-color: aqua;
    left: 0;
    bottom: -5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    animation: appear 0.1s linear;
}*/

@-webkit-keyframes appear{
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes appear{
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .header__navbar {
        justify-content: flex-end;
        padding: 0rem 2rem;
    }

    .header__navbar-container {
        display: none;
    }

    .header__navbar-menu {
        display: flex;
        justify-content: right;
    }

    .active-link::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background-color: aqua;
        left: 0;
        bottom: -1px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .header__navbar-menu_container-links a {
        padding-bottom: 3px;
    }

/*    .link:hover::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background-color: aqua;
        left: 0;
        bottom: -1px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        animation: appear 0.1s linear;
    }*/
}
.hero {
    display: flex;
    flex-direction: row;
}

.hero__content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero__content h1 {
    text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 96px;
    line-height: 107px;
}

.hero__content-title {
    color: #ffffff;
    padding-bottom: 1rem;
}

.hero__content-subtitle {
    color: #00FFF0;
    font-family: var(--font-family);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 74px;
    padding-bottom: 1rem;
}

.hero__content-p {
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.hero__content p {
    text-align: left;
    color: #ffffff;
    font-family: var(--font-family);
    font-weight: 300;
}

.hero__image-container {
    display: flex;
    flex: 1 1;
}

.hero__image {
    height: 625px;
    -webkit-filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
    z-index: 0;
    border-radius: 1%;
    margin-left: 10%;
}

@media screen and (max-width: 1050px) {
    .hero {
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .hero__image-container {
        justify-content: center;
    }

    .hero__content h1 {
        text-align: center;
    }

    .hero__content p {
        text-align: center;
    }

    .hero__image {
        margin-left: 0%;
    }
}

@media screen and (max-width: 550px) {

    .hero {
        flex-direction: column;
        grid-gap: 2rem;
        gap: 2rem;
    }

    .hero__image-container {
        justify-content: center;
        padding-bottom: 2rem;
    }

    .hero__image {
        width: 90%;
        height: 100%;
        margin-left: 0%;   
    }

    .hero__content h1 {
        text-align: center;
        font-weight: 400;
        font-size: 96px;
        line-height: 107px;
    }

    .hero__content-subtitle {
        padding-bottom: 1rem;
    }

    .hero__content p {
        text-align: center;
        color: #ffffff;
    }
}

.blogPage {
    display: inline-block;
    text-align: left;
}

.blogPage__hero {
    display: flex;
    flex-direction: row;
    flex: 1 1;
}

.blogPage__hero-content {
    flex: 1 1;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-right: 10%;
}

.blogPage__hero-content h1 {
    font-family: var(--font-family);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 84px;
    line-height: 107px;
}

.blogPage__hero-content h2 {
    font-family: var(--font-family);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 107px;
    color:aqua;
}

.blogPage__hero-image {
    display: flex;
    flex: 1 1;
}

.blogPage__image {
    height: 500px;
    -webkit-filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
    z-index: 0;
    border-radius: 1%;
    margin-left: 30%;
}

.blogPage__content {
    padding: 0% 10%;
}

.blogPage__hero-content h1, h2, h3, h4 {
    font-family: var(--font-family);
    color: white;
}

.blogPage__content p, ol, ul, li {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 30px;
    color: white;
}

ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

.divider {
    border-top: 3px solid #bbb;
    margin: 5% 10%;
}
blockquote{
  font-size: 1.4em;
  width:60%;
  margin:50px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid aqua ;
  line-height:1.6;
  position: relative;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#78C0A8;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color: aqua;
  font-style: normal;
  font-weight: normal;
  margin-top:1em;
}

@media screen and (max-width: 1540px){

    .blogPage__hero-content {
        margin-right: 0%;
    }

    .blogPage__hero-content h1 {
        font-size: 60px;
        line-height: 80px;
    }

    .blogPage__image {
        margin-left: 25%;
    }
}

@media screen and (max-width: 1200px){

    .blogPage {
        text-align: center;
    }

    .section__padding {
        padding: 1rem, 2rem !important;
    }

    .blogPage__content {
        padding: 0 2%;
    }

    .blogPage__hero-content {
        margin-right: -5%;
    }

    .blogPage__hero-content h1 {
        font-size: 60px;
        line-height: 80px;
    }

    .blogPage__image {
        margin-left: 5%;
    }
}


@media screen and (max-width: 1000px){
    
    .blogPage {
        flex-direction: column;
    }

    .blogPage__hero {
        display: flex;
        flex-direction: column;
        flex: 1 1;
    }

    .blogPage__hero-content {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 0%;
    }

    .blogPage__hero-content h1 {
        text-align: center;
        font-size: 60px;
        line-height: 70px;
    }

    .blogPage__hero-content h2 {
        text-align: center;
        font-size: 32px;
        line-height: 90px;
        color:aqua;
    }

    .blogPage__hero-image {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }

    .blogPage__content img{
        max-width: 100% !important;
        height: auto;
    }

    blockquote{
        font-size: 1.4em;
        width:80%;
        margin:50px auto;
        font-family:Open Sans;
        font-style:italic;
        color: #555555;
        padding:1.2em 30px 1.2em 75px;
        border-left:8px solid aqua ;
        line-height:1.6;
        position: relative;
    }

    .blogPage__image {
        height: 400px;
        -webkit-filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
                filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
        z-index: 0;
        border-radius: 1%;
        margin-left: 0%;
    }

    .blogPage__hero-content h1{
        font-size: 40px !important;
        line-height: 40px !important;
    }
}
