@font-face{
    font-family: "Sansation";
    src: url("./Assets/sansation/Sansation_Regular.ttf");
}

@font-face {
    font-family: "Matter";
    src: url("./Assets/matter-font/Matter-Medium.otf");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "Matter";
    src: url("./Assets/matter-font/Matter-Light.otf");
    font-style: normal;
    font-weight: 300;
}


:root {
    --color-bg: linear-gradient(90deg, #08062a 0%, #1c1e3c 53.12%, #08062a 100%);
    /*--font-family: 'Sansation', sans-serif;*/
    --font-family: 'Matter', sans-serif;
}
