.header__navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83px;
    background: #070630;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: .2s ease-in;
}

.header__navbar-hidden {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83px;
    background: #ffffff00;
    position: sticky;
    top: 0;
    z-index: 1;
    transition: .2s ease-in;
}

.header__navbar-container {
    display: flex;
    flex-direction: row;
    gap: 37px;
}

    .header__navbar-container a,
    .header__navbar-menu_container-links a {
        color: white;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        font-family: var(--font-family);
        cursor: pointer;
    }

.header__navbar-menu {
    display: none;
    position: relative;
}

    .header__navbar-menu svg {
        cursor: pointer;
    }

.header__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: rgb(7, 6, 48);
    padding: 2rem;
    position: absolute;
    gap: 20px;
    right: -2rem;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
    z-index: 1;
}

.link {
    display: inline-block;
    position: relative;
}

    .link:active {
        transform: translateY(1px);
    }

.active-link::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 100%;
    background-color: aqua;
    left: 0;
    bottom: -5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

/*.link:hover::after {
    position: absolute;
    content: "";
    height: 3px;
    width: 100%;
    background-color: aqua;
    left: 0;
    bottom: -5px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    animation: appear 0.1s linear;
}*/

@keyframes appear{
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@media screen and (max-width: 550px) {
    .header__navbar {
        justify-content: flex-end;
        padding: 0rem 2rem;
    }

    .header__navbar-container {
        display: none;
    }

    .header__navbar-menu {
        display: flex;
        justify-content: right;
    }

    .active-link::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background-color: aqua;
        left: 0;
        bottom: -1px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

    .header__navbar-menu_container-links a {
        padding-bottom: 3px;
    }

/*    .link:hover::after {
        position: absolute;
        content: "";
        height: 2px;
        width: 100%;
        background-color: aqua;
        left: 0;
        bottom: -1px;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        animation: appear 0.1s linear;
    }*/
}