.hero {
    display: flex;
    flex-direction: row;
}

.hero__content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.hero__content h1 {
    text-align: left;
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 96px;
    line-height: 107px;
}

.hero__content-title {
    color: #ffffff;
    padding-bottom: 1rem;
}

.hero__content-subtitle {
    color: #00FFF0;
    font-family: var(--font-family);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 64px;
    line-height: 74px;
    padding-bottom: 1rem;
}

.hero__content-p {
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.hero__content p {
    text-align: left;
    color: #ffffff;
    font-family: var(--font-family);
    font-weight: 300;
}

.hero__image-container {
    display: flex;
    flex: 1;
}

.hero__image {
    height: 625px;
    filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
    z-index: 0;
    border-radius: 1%;
    margin-left: 10%;
}

@media screen and (max-width: 1050px) {
    .hero {
        flex-direction: column;
        gap: 2rem;
    }

    .hero__image-container {
        justify-content: center;
    }

    .hero__content h1 {
        text-align: center;
    }

    .hero__content p {
        text-align: center;
    }

    .hero__image {
        margin-left: 0%;
    }
}

@media screen and (max-width: 550px) {

    .hero {
        flex-direction: column;
        gap: 2rem;
    }

    .hero__image-container {
        justify-content: center;
        padding-bottom: 2rem;
    }

    .hero__image {
        width: 90%;
        height: 100%;
        margin-left: 0%;   
    }

    .hero__content h1 {
        text-align: center;
        font-weight: 400;
        font-size: 96px;
        line-height: 107px;
    }

    .hero__content-subtitle {
        padding-bottom: 1rem;
    }

    .hero__content p {
        text-align: center;
        color: #ffffff;
    }
}
