.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #05032a;
    height: 83px;
}

.footer::after{
    content: '';
    flex: 1;
}

.footer__copyright {
    flex: 1;
    padding-left: 2rem;
}

.footer__copyright p {
    font-family: var(--font-family);
    color: white;
    font-weight: 400;
    font-size: 18px;
}

@media screen and (max-width: 1050px) {
    .footer::after {
        flex: 0;
    }

    .footer__copyright p {
        display: flex;
    }
}

@media screen and (max-width: 550px){
    .footer::after {
        flex: 0;
    }

    .footer__copyright p {
        font-size: 12px;
    }

    .footer__copyright {
        padding-left: 2rem;
    }
}