.socials__container {
    display: flex;
    gap: 1rem;
}

.socials {
    transition: all .2s ease-in-out;
}

    .socials:hover {
        transform: scale(1.2);
        color: white !important;
    }