* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 5.2rem;
}

body{
    background: black;
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    background: var(--color-bg);
    text-align: center;
}

.section__padding {
    padding: 3rem 6rem;
}

@media screen and (max-width: 900px) {

    .section__padding{
        padding: 1rem 2rem;
    }

}