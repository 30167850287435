@import url('https://fonts.googleapis.com/css?family=Playfair+Display:700|IBM+Plex+Sans:500&display=swap');

:root {
    --color-primary: #6b7a8f;
    --color-secondary: #101118;
    --color-accent: #1d1f2f;
    --color-focus: aqua;
    --base-duration: 600ms;
    --base-ease: cubic-bezier(0.25, 0.46, 0.45, 0.84);
    --slide-width: 40vmin;
    --slide-margin: 3vmin;
    --slide-height: 60vmin;
}

h1, h2, h3 {
    font-family: 'Playfair Display', serif;
}

.icon {
    fill: var(--color-primary);
    width: 100%;
}

.btn {
    background-color: #ffffff;
    border: none;
    border-radius: 20rem;
    color: black;
    cursor: pointer;
    font-family: 'Matter';
    font-weight: 300;
    padding: 1rem 2.5rem 1.125rem;
}

    .btn:focus {
        outline-offset: 1px;
    }

    .btn:active {
        transform: translateY(1px);
    }

.btn a {
    font-weight: 400;
}

.slider__viewAll {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(100% + 5rem);
    width: 100%;
}

.slider__controls {
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc(100% + 1rem);
    width: 100%;
}

    .slider__controls .btn {
        --size: 3rem;
        align-items: center;
        background-color: transparent;
        border: 2px solid transparent;
        border-radius: 100%;
        display: flex;
        height: var(--size);
        padding: 0;
        width: var(--size);
    }

        .slider__controls .btn:focus {
            border-color: var(--color-focus);
            outline: none;
        }

    .slider__controls .btn--previous > * {
        transform: rotate(180deg);
    }

.slider {
    height: var(--slide-height);
    margin: 0 auto;
    position: relative;
    width: var(--slide-width);
}

.slider__wrapper {
    display: flex;
    margin: 0 calc(var(--slide-margin) * -1);
    position: absolute;
    transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.slide {
    align-items: center;
    color: white;
    display: flex;
    flex: 1;
    flex-direction: column;
    height: var(--slide-height);
    justify-content: center;
    margin: 0 var(--slide-margin);
    opacity: 0.25;
    position: relative;
    text-align: center;
    transition: opacity calc(var(--base-duration) / 2) var(--base-ease), transform calc(var(--base-duration) / 2) var(--base-ease);
    width: var(--slide-width);
    z-index: 1;
}

.slide--previous:hover, .slide--next:hover {
    opacity: 0.5;
}

.slide--previous {
    cursor: w-resize;
}

    .slide--previous:hover {
        transform: translateX(2%);
    }

.slide--next {
    cursor: e-resize;
}

    .slide--next:hover {
        transform: translateX(-2%);
    }

.slide--current {
    --x: 0;
    --y: 0;
    --d: 50;
    opacity: 1;
    pointer-events: auto;
    user-select: auto;
}

@media (hover: hover) {
    .slide--current:hover .slide__image-wrapper {
        transform: scale(1.025) translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }
}

.slide__image-wrapper {
    border-radius: 1%;
    height: 100%;
    left: 0%;
    overflow: hidden;
    position: absolute;
    top: 0%;
    transition: transform calc(var(--base-duration) / 4) var(--base-ease);
    width: 100%;
}

.slide__image {
    --d: 20;
    height: 100%;
    left: 0%;
    object-fit: cover;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0%;
    transition: opacity var(--base-duration) var(--base-ease), transform var(--base-duration) var(--base-ease);
    user-select: none;
    width: 100%;
}

@media (hover: hover) {
    .slide--current .slide__image {
        transform: translate(calc(var(--x) / var(--d) * 1px), calc(var(--y) / var(--d) * 1px));
    }
}

.slide__headline {
    font-family: 'Matter';
    font-size: 4vmin;
    font-weight: 400;
    filter: drop-shadow(-2px 1px 1px rgba(0, 0, 0, 0.5));
    position: relative;
    background: rgb(5 14 46 / 80%);
    border-radius: 10px;
    padding: 1rem;
}

.slide__content {
    --d: 60;
    opacity: 0;
    padding: 4vmin;
    position: relative;
    transition: transform var(--base-duration) var(--base-ease);
    visibility: hidden;
}

.slide--current .slide__content {
    animation: fade-in calc(var(--base-duration) / 2) var(--base-ease) forwards;
    visibility: visible;
}

@media (hover: hover) {
    .slide--current .slide__content {
        transform: translate(calc(var(--x) / var(--d) * -1px), calc(var(--y) / var(--d) * -1px));
    }
}

.slide__content > * + * {
    margin-top: 2rem;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media screen and (max-width: 1050px){

    .slide__headline {
        font-size: 5vmin;
        font-weight: 600;
        position: relative;
    }

    .btn {
        padding: 0.5rem 1.5rem 0.5rem;
    }

}

@media screen and (max-width: 700px){
    :root {
        --slide-width: 60vmin;
        --slide-height: 90vmin;
    }

    .slide__headline {
        font-size: 6vmin;
        font-weight: 600;
        position: relative;
    }
}