.timelinestyle__TimelineMain-sc-cif21b-2 {
    left: unset !important;
}

.timelinestyle__Outline-sc-cif21b-3 {
    background: #00fff0 !important;
}


/*timeline node borders*/
.timeline-horizontal-cardstyles__Circle-sc-18iuiou-3:not(.using-icon) {
    background: #00fff0 !important;
}

    .timeline-horizontal-cardstyles__Circle-sc-18iuiou-3:not(.using-icon).active::after {
        background: #005050 !important;
    }

/*active timeline node*/
.timeline-card-titlestyles__TitleWrapper-sc-13izrht-0.active {
    background: unset !important;
    color: #ffffff !important;
}

    .timeline-card-titlestyles__TitleWrapper-sc-13izrht-0.active::after {
        position: absolute;
        content: "";
        height: 2px;
        bottom: 0px;
        margin: 5px auto;
        left: 0;
        right: 0;
        width: 80%;
        background: #005050;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }

.timeline-card-titlestyles__TitleWrapper-sc-13izrht-0 {
    color: #ffffff !important;
}

/*card container*/
.timeline-horizontal-cardstyles__TimelineContentContainer-sc-18iuiou-5.horizontal {
    min-width: 0 !important;
    padding-right: 14% !important;
    padding-left: 14% !important;
    padding-bottom: 2rem;
}

.timeline__custom-card {
    display: flex;
    flex: 1;
    gap: 5rem;
    align-items: center;
}

.timeline__card-title {
    display: flex;
    font-family: 'Sansation';
    color: #ffffff;
    justify-content: center;
    font-size: 30px;
    padding: 2rem;
}

.timeline__card {
    background: #fff0 !important;
}

.timeline__custom-card-text {
    color: white;
    font-family: 'Sansation';
}

.timeline__custom-card-image {
    width: 100%;
}

.timeline__custom-card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

/*button tray background*/
.timeline-controlstyles__TimelineNavWrapper-sc-1apb8f9-0 {
    background: unset !important;
}

/*actual button background*/
.timeline-controlstyles__TimelineNavButton-sc-1apb8f9-2 {
    background: #000000 !important;
    color: #00fff0 !important;
}

.timeline-card-contentstyles__TimelineContentDetailsWrapper-sc-d7qjm1-6 {
    max-height: unset !important;
    overflow: unset !important;
}

    .timeline-card-contentstyles__TimelineContentDetailsWrapper-sc-d7qjm1-6.show-less {
        overflow: unset !important;
    }

/*Timeline width*/
.timeline-horizontalstyles__TimelineItemWrapper-sc-1dd6qhe-1 {
    width: 250px !important;
}

/*Timeline node titles*/
.timeline-card-titlestyles__TitleWrapper-sc-13izrht-0{
    font-family: 'Matter';
    font-style: normal;
    font-weight: 300 !important;
}

@media screen and (max-width: 1050px) {
    /*Timeline width*/
    .timeline-horizontalstyles__TimelineItemWrapper-sc-1dd6qhe-1 {
        width: 130px !important;
    }

    /*card container*/
    .timeline-horizontal-cardstyles__TimelineContentContainer-sc-18iuiou-5.horizontal {
        padding-right: 10% !important;
        padding-left: 10% !important;
        padding-bottom: 2rem !important;
    }

    .timeline__custom-card {
        flex-direction: column;
        gap: 0rem;
    }

    .timeline__custom-card-image {
        width: 50%;
        padding-bottom: 3rem;
    }
}

@media screen and (max-width: 550px) {

    /*Timeline width*/
    .timeline-horizontalstyles__TimelineItemWrapper-sc-1dd6qhe-1 {
        width: 90px !important;
    }

    /*card container*/
    .timeline-horizontal-cardstyles__TimelineContentContainer-sc-18iuiou-5.horizontal {
        min-width: 0 !important;
        padding-right: 5% !important;
        padding-left: 5% !important;
    }
}
