.blogPage {
    display: inline-block;
    text-align: left;
}

.blogPage__hero {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.blogPage__hero-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    margin-right: 10%;
}

.blogPage__hero-content h1 {
    font-family: var(--font-family);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 84px;
    line-height: 107px;
}

.blogPage__hero-content h2 {
    font-family: var(--font-family);
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 107px;
    color:aqua;
}

.blogPage__hero-image {
    display: flex;
    flex: 1;
}

.blogPage__image {
    height: 500px;
    filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
    z-index: 0;
    border-radius: 1%;
    margin-left: 30%;
}

.blogPage__content {
    padding: 0% 10%;
}

.blogPage__hero-content h1, h2, h3, h4 {
    font-family: var(--font-family);
    color: white;
}

.blogPage__content p, ol, ul, li {
    font-family: var(--font-family);
    font-size: 18px;
    line-height: 30px;
    color: white;
}

ul {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }

.divider {
    border-top: 3px solid #bbb;
    margin: 5% 10%;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
blockquote{
  font-size: 1.4em;
  width:60%;
  margin:50px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  padding:1.2em 30px 1.2em 75px;
  border-left:8px solid aqua ;
  line-height:1.6;
  position: relative;
}

blockquote::before{
  font-family:Arial;
  content: "\201C";
  color:#78C0A8;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

blockquote::after{
  content: '';
}

blockquote span{
  display:block;
  color: aqua;
  font-style: normal;
  font-weight: normal;
  margin-top:1em;
}

@media screen and (max-width: 1540px){

    .blogPage__hero-content {
        margin-right: 0%;
    }

    .blogPage__hero-content h1 {
        font-size: 60px;
        line-height: 80px;
    }

    .blogPage__image {
        margin-left: 25%;
    }
}

@media screen and (max-width: 1200px){

    .blogPage {
        text-align: center;
    }

    .section__padding {
        padding: 1rem, 2rem !important;
    }

    .blogPage__content {
        padding: 0 2%;
    }

    .blogPage__hero-content {
        margin-right: -5%;
    }

    .blogPage__hero-content h1 {
        font-size: 60px;
        line-height: 80px;
    }

    .blogPage__image {
        margin-left: 5%;
    }
}


@media screen and (max-width: 1000px){
    
    .blogPage {
        flex-direction: column;
    }

    .blogPage__hero {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .blogPage__hero-content {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 0%;
    }

    .blogPage__hero-content h1 {
        text-align: center;
        font-size: 60px;
        line-height: 70px;
    }

    .blogPage__hero-content h2 {
        text-align: center;
        font-size: 32px;
        line-height: 90px;
        color:aqua;
    }

    .blogPage__hero-image {
        display: flex;
        justify-content: center;
        padding-bottom: 2rem;
    }

    .blogPage__content img{
        max-width: 100% !important;
        height: auto;
    }

    blockquote{
        font-size: 1.4em;
        width:80%;
        margin:50px auto;
        font-family:Open Sans;
        font-style:italic;
        color: #555555;
        padding:1.2em 30px 1.2em 75px;
        border-left:8px solid aqua ;
        line-height:1.6;
        position: relative;
    }

    .blogPage__image {
        height: 400px;
        filter: drop-shadow(10px 15px 4px rgba(0, 0, 0, 0.25));
        z-index: 0;
        border-radius: 1%;
        margin-left: 0%;
    }

    .blogPage__hero-content h1{
        font-size: 40px !important;
        line-height: 40px !important;
    }
}